<template>
  <div class="text-left ConfirmResetPasswordNew"  style="overflow-x:hidden;">
    <div class="row h-100">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div>
          <!-- Logo -->
          <div class="px-5">
            <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell"
              class="img-fluid"
              fluid
              @click="$router.push('/')"
            />
          </div>

          <div class="TitleDiv">
            <p class="title">Welcome back!</p>
            <p class="SubTitle text-center">
              Sign back in to your account to get access to all the features and
              updates you’ve missed. Create a new quiz or update your current
              one to improve your website and boost your conversion rate.
            </p>
          </div>

          <!-- Inputs -->
          <div class="mt-5">
            <div class="new-form-element px-5">
              <p class="m-0 pl-1">Email</p>
              <div class="NewFormInput d-flex align-items-center w-100 py-2">
                <input
                  type="text"
                  class="mx-2 flex-grow-1"
                  placeholder="Enter your  email"
                  v-model="userDetail.email"
                />
              </div>
            </div>
            <div class="new-form-element mt-3 px-5">
              <p class="m-0 pl-1">Create a password</p>
              <div class="NewFormInput d-flex align-items-center w-100 py-2">
                <input
                  :type="passwordFieldType"
                  class="mx-2 flex-grow-1"
                  placeholder="Enter your password"
                  v-model="userDetail.password"
                />
                <div
                  style="cursor: pointer"
                  class="mr-2"
                  v-if="passwordFieldType == 'text'"
                >
                  <b-icon-eye style="color: #d1d1d1" @click="switchPass" />
                </div>
                <div
                  style="cursor: pointer"
                  class="mr-2"
                  v-else
                  @click="switchPass"
                >
                  <b-icon-eye-slash style="color: #d1d1d1" />
                </div>
              </div>
            </div>
            <div class="new-form-element mt-3 px-5">
              <p class="m-0 pl-1">Confirm password</p>
              <div class="NewFormInput d-flex align-items-center w-100 py-2">
                <input
                  type="text"
                  class="mx-2 flex-grow-1"
                  placeholder="Enter your password"
                  v-model="userDetail.confirmPassword"
                />
              </div>
              <p class="m-0 pl-1 mt-1" v-if="userDetail.confirmPassword != ''">
                <span class="text-danger" v-if="!checkConfirmPassWord">
                  Password must match.
                </span>
                <span class="text-success" v-else> Password matched! </span>
              </p>
            </div>

            <div class="mt-4 px-5">
              <button
                :disabled="!isResetValid"
                @click="UpdatePassword"
                class="btn w-100 NewFormInput-Btn px-3 py-2"
              >
                <span v-if="!resetPassSpinner">Reset Password</span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-lg-block col-lg-5 h-100 objectSection"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      userDetail: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      passwordFieldType: "password",
      resetPassSpinner: false,
    };
  },
  methods: {
    switchPass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    async UpdatePassword() {
      this.resetPassSpinner = true;
      try {
        let data = {
          email: this.userDetail.email,
          password: this.userDetail.password,
          password_confirmation: this.userDetail.confirmPassword,
          token: this.$route.params.id,
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/password/reset`, data);
        if (response.status == 200 && response.data.status == "fail") {
          this.$toasted.show(`${response.data.data}`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        } else if (
          response.status == 200 &&
          response.data.status == "success"
        ) {
          this.$toasted.show(`${response.data.data}`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });

          setTimeout(
            function () {
              this.$router.push({ name: "Login" });
            }.bind(this),
            1500
          );
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.resetPassSpinner = false;
      }
    },
  },
  computed: {
    ...mapGetters(["GetTokenFromMetaTag"]),
    checkConfirmPassWord() {
      return this.userDetail.password === this.userDetail.confirmPassword;
    },
    isResetValid() {
      return (
        this.userDetail.password !== "" &&
        this.userDetail.email !== "" &&
        this.userDetail.confirmPassword !== "" &&
        this.checkConfirmPassWord
      );
    },
  },
};
</script>

<style scoped>
.ConfirmResetPasswordNew {
  height: 100vh;
}
.ConfirmResetPasswordNew .MainSection {
  padding: 2% 5%;
  background: #ffffff;
}
.MainSection .TitleDiv {
  padding: 48px;
}
.MainSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #161e34;
}
.objectSection {
  background-image: url("/assets/img/new-website/loginObject.png");
  background-color: #ffffff;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100vh;
}

.SignUpCheckbox p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 2px !important;
  color: #161e34;
}
.SignUpCheckbox a {
  text-decoration: none;
  color: #4d1b7e;
}

.NewFormInput-Btn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.NewFormInput-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}
.NewFormInput-Btn:disabled {
  background: #4d1b7e;
  color: #ffffff;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}
    .NewFormInput {
        border: 1px solid #4D1B7E;
    border-radius: 10px;
        box-sizing: border-box;
        background:#ffffff;
    }
    
    .NewFormInput input {
        background: transparent;
        border: none;
        outline: none;
    }
    .NewFormInput input:focus {
        border: none;
        outline: none;
    }
    .NewFormInput input::placeholder {
       font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    text-align: start;
    
    color: #D1D1D1;
    }
    
</style>
